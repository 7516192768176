<template>
    <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
        <div class="primary-section-content">
            <hb-header full>
                <hb-page-header
                    title="Download Center"
                    description="Documents that Hummingbird has generated are sent here. You can view these documents online or download them as PDFs in order to print them."
                >
                </hb-page-header>
            </hb-header>

            <files-list :key="report_key"/>

        </div>

    </div>
</template>

<script type="text/babel">
    import FilesList from './FilesList.vue';
    import { EventBus } from "../../EventBus.js";

    export default {
        name: "DownloadCenterIndex",
        data() {
            return {
                report_key: 0
            }
        },
        components:{
            FilesList
        },
        created (){
            EventBus.$on('refetch_data', () => this.fetchData());
        },
        destroyed(){
            EventBus.$off('refetch_data', () => this.fetchData());
        },
        methods:{
            fetchData(){
                this.report_key++;
            }
        }
    }
</script>