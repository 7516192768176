<template>
    <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
        <div class="primary-section-content">
            
            <status class="mt-3" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <status class="mt-3" @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
            
            <v-row class="ma-0 pa-0" v-if="properties.length > 0">
                <v-col cols="12" class="px-0 d-flex align-center">
                    <div style="width:130px;" v-show="properties.length !== 1">
                    <v-select
                        :items="properties"
                        item-text="number"
                        return-object
                        background-color="white"
                        outlined
                        dense
                        hide-details
                        :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                        v-model="property"
                        height="40"
                        id="property"
                        class="vuetify-input-fieldset-fix"
                    >
                        <template v-slot:selection="data">
                        <div class="hb-input-overflow d-flex align-center">
                            <hb-icon color="#101318" class="pr-1">mdi-facility-custom-2</hb-icon>
                            <span v-if="data.item.number">{{data.item.number}}</span>
                            <span v-else>{{data.item.Address.city}}</span>
                        </div>
                        </template>
                        <template v-slot:item="data">
                        <div class="hb-input-overflow d-flex align-center">
                            <hb-icon color="#101318" class="pr-1">mdi-facility-custom-2</hb-icon>
                            <span v-if="data.item.number" class="pr-1">{{data.item.number}} -</span>{{data.item.Address.city}} - {{data.item.Address.address}} {{data.item.Address.address2}}
                        </div>
                        </template>
                    </v-select>
                    </div>
                    <div :class="{'pl-2' : properties.length !== 1}">
                        <v-menu v-model="showFilters" :close-on-content-click="false" offset-y content-class="hb-elevation-large">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="d-flex align-center pl-4 pr-2 select-filter" :class="{ 'download-center-filters-active' : showFilters }">
                                    <span class="font-weight-regular hb-default-font-size">{{setFilterText.length > 0 ?  setFilterText : 'Filter'}}</span>
                                    <v-spacer></v-spacer>
                                    <hb-icon color="#637381">mdi-filter-variant</hb-icon>
                                </div>
                            </template>
                            <v-card width="300">
                                <v-card-text class="pa-4" style="overflow: auto;">
                                    <div class="hb-text-light hb-default-font-size font-weight-medium">Date Range</div>
                                    <v-select
                                        :items="dateRangeOptions"
                                        background-color="white"
                                        hide-details
                                        single-line
                                        label="Select"
                                        :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                                        v-model="dateRange"
                                        id="dateRange"
                                        class="pt-0"
                                    >
                                    </v-select>
                                    <span v-if="dateRange === 'Custom'">
                                    <hb-date-picker
                                        :max="todaysDate"
                                        @click:clear="customStartDate = ''"
                                        label="Start Date"
                                        :clearable="true"
                                        :solo="false"
                                        data-vv-as="start_date"
                                        v-model="customStartDate"
                                    ></hb-date-picker>
                                    <hb-date-picker
                                        v-if="customStartDate.length > 0"
                                        @click:clear="customEndDate = ''"
                                        label="End Date"
                                        :min="customStartDate"
                                        :max="todaysDate"
                                        :clearable="true"
                                        :solo="false"
                                        data-vv-as="end_date"
                                        v-model="customEndDate"
                                    ></hb-date-picker>
                                    </span>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions class="px-4 py-3" style="background: linear-gradient(180deg, #fafbfc 0%, #ebeff2 100%);">
                                    <hb-link color="tertiary" @click="resetFilters">Clear Filters</hb-link>
                                    <v-spacer></v-spacer>
                                    <hb-btn color="secondary" small @click="goFilter(dateRange, customStartDate, customEndDate)" :disabled="dateRange.length === 0 || (dateRange === 'Custom' && (customStartDate.length === 0 || customEndDate.length === 0))">Done</hb-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </div>
                </v-col>
            </v-row>

            <v-card class="mt-0 mb-3" elevation="0">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loadingDocuments"
                    loading-text="Loading Documents..."
                    disable-pagination
                    hide-default-footer
                    class="hb-data-table"
                    :custom-sort="customSort"
                >
                <template v-slot:item.property_number="{ item }">
                    <div class="d-flex align-top">
                    <hb-icon color="#101318" small class="pr-1" style="margin-top:1px;">mdi-facility-custom-2</hb-icon>
                    <span v-if="property.number" class="pr-1">{{property.number}}</span>
                    <span v-else>{{property.Address.city}}</span>
                    </div>
                </template>
                <template v-slot:item.pages="{ item }">
                    {{item.attributes.number_of_documents}}
                </template>
                <template v-slot:item.created_on="{ item }">
                    {{item.created_on | formatDateTimeCustom('MMM DD, YYYY [@] h:mma')}}
                </template>
                <template v-slot:item.last_downloaded_on="{ item }">
                    <div class="py-3">
                    <div v-if="item.attributes.last_downloaded_on">{{item.attributes.last_downloaded_on | formatDateTimeCustom('MMM DD, YYYY [@] h:mma')}}</div>
                    <div v-if="item.attributes.downloaded_by" class="hb-text-light mt-1 mb-n1">By: {{item.attributes.downloaded_by}}</div>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }" >
                        <hb-menu
                            options
                            align-right
                        >
                            <v-list>
                                <v-list-item :ripple="false" :disabled="!item.id" :href="item.src" @click="getFile(item.id)">
                                    <v-list-item-title>
                                        View/Print
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                    </hb-menu>
                </template>
                </v-data-table>
                <v-divider v-if="infinityLoader"></v-divider>
                <div class="d-flex align-center justify-center" v-if="infinityLoader">
                    <v-progress-circular indeterminate width="2" size="24" class="mx-0 pa-0 my-4" color="primary"></v-progress-circular>
                </div>
            </v-card>
        </div>

    </div>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import { mapGetters, mapActions } from 'vuex';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';

    export default {
        name: "FilesList",
        data() {
            return {
                showFilters: false,
                dateRangeOptions: ['All Time', 'Last 30 Days', 'Custom'],
                dateRange: '',
                customStartDate: '',
                customEndDate: '',
                setFilterText: '',
                todaysDate: '',
                loadingDocuments: false,
                showFilters: false,
                headers: [
                    { text: 'Property', value: 'property_number', sortable: false },
                    { text: 'Document Name', value: 'file_name' },
                    { text: '# of Documents', value: 'pages', width: '140' },
                    { text: 'Generated On', value: 'created_on', width: '140' },
                    { text: 'Last Downloaded On', value: 'last_downloaded_on', width: '180' },
                    { text: '', value: 'actions', align: 'right', sortable: false },
                ],
                property: '',
                items: [],
                infinityLoader: false,
                currentPage: 0,
                totalPages: 0
            }
        },
        components:{
            Status,
            HbDatePicker
        },
        mounted() {
            this.checkIfBottom();
        },
        async created (){
            this.todaysDate = moment().startOf('day').format('YYYY-MM-DD');
            if(this.properties.length > 0){
                this.property = this.properties[0];
            } else {
                setTimeout(() => {
                    if(this.properties.length > 0){
                        this.property = this.properties[0];
                    }
                }, 1000);
            }
        },
        computed:{
          ...mapGetters({
              isAdmin: 'authenticationStore/isAdmin',
              properties: 'propertiesStore/filtered',
              getUserData: 'authenticationStore/getUserData'
          }),
        },
        methods:{
          customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index[0] === 'file_name') {
                        var fa = '';
                        if(a.id && a.file_name){
                            fa = a.file_name.toLowerCase();
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && b.file_name){
                            fb = b.file_name.toLowerCase();
                        } else {
                            ''
                        }
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (index[0] === 'pages') {
                        var fa = '';
                        if(a.id && a.attributes.number_of_documents && typeof(a.attributes.number_of_documents) == 'number'){
                            fa = a.attributes.number_of_documents;
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && b.attributes.number_of_documents && typeof(b.attributes.number_of_documents) == 'number'){
                            fb = b.attributes.number_of_documents;
                        } else {
                            ''
                        }
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (index[0] === 'created_on') {
                        if (!isDesc[0]) {

                            if (a.created_on > b.created_on) {
                                return -1;
                            }
                            if (a.created_on < b.created_on) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (a.created_on < b.created_on) {
                                return -1;
                            }
                            if (a.created_on > b.created_on) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (index[0] === 'last_downloaded_on') {
                        var fa = '';
                        if(a.id && a.attributes.last_downloaded_on){
                            fa = a.attributes.last_downloaded_on;
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && b.attributes.last_downloaded_on){
                            fb = b.attributes.last_downloaded_on;
                        } else {
                            ''
                        }
                        
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                });

                return items;
            },

            async fetchData(dateRange, customStartDate, customEndDate){
                dateRange = this.dateRange; 
                customStartDate = this.customStartDate;
                customEndDate = this.customEndDate;
                
                var currentProperty = '';
                
                if(this.property && this.property.id){
                    currentProperty = this.property;
                } else if(!this.property && this.properties.length > 0){
                    currentProperty = this.properties[0];
                }

                if(currentProperty && currentProperty.id){
                    if(this.items.length === 0){
                        this.loadingDocuments = true;
                    } else {
                        this.infinityLoader = true;
                    }

                    let page = Number(this.currentPage) + 1;
                    if(!dateRange || dateRange === 'All Time'){
                        let r = await api.get(this, api.PROPERTIES + currentProperty.id + '/files?page=' + page);
                        if(r.pagination && r.pagination.current_page){
                            this.currentPage = r.pagination.current_page;
                        }
                        if(r.pagination && r.pagination.total_pages){
                            this.totalPages = r.pagination.total_pages;
                        }
                        r.files.forEach(file =>
                            this.items.push(file)
                        )
                    } else if(dateRange === 'Last 30 Days'){
                        
                        let r = await api.get(this, api.PROPERTIES + currentProperty.id + '/files?start_date=' + encodeURIComponent(moment().startOf('day').subtract(30, 'days').format()) + '&end_date=' + encodeURIComponent(moment().endOf('day').format()) + '&page=' + page);
                        if(r.pagination && r.pagination.current_page){
                            this.currentPage = r.pagination.current_page;
                        }
                        if(r.pagination && r.pagination.total_pages){
                            this.totalPages = r.pagination.total_pages;
                        }
                        r.files.forEach(file =>
                            this.items.push(file)
                        )
                    } else if(dateRange === 'Custom' && customStartDate && customEndDate){
                        let r = await api.get(this, api.PROPERTIES + currentProperty.id + '/files?start_date=' + encodeURIComponent(moment(customStartDate).startOf('day').format()) + '&end_date=' + encodeURIComponent(moment(customEndDate).endOf('day').format()) + '&page=' + page);
                        if(r.pagination && r.pagination.current_page){
                            this.currentPage = r.pagination.current_page;
                        }
                        if(r.pagination && r.pagination.total_pages){
                            this.totalPages = r.pagination.total_pages;
                        }
                        r.files.forEach(file =>
                            this.items.push(file)
                        )
                    }

                    this.loadingDocuments = false;
                    this.infinityLoader = false;
                }
            },
            async resetFilters(){
                this.items = [];
                this.currentPage = 0;
                this.totalPages = 0;
                this.setFilterText = '';
                this.dateRange = '';
                this.customStartDate = '';
                this.customEndDate = '';
                this.showFilters = false;
                await this.bottomVisible();
            },

            async goFilter(dateRange, customStartDate, customEndDate){
                this.items = [];
                this.currentPage = 0;
                this.totalPages = 0;
                this.setFilterText = dateRange;
                if(dateRange !== 'Custom'){
                    this.customStartDate = '';
                    this.customEndDate = '';
                }
                this.showFilters = false;
                await this.bottomVisible(dateRange, customStartDate, customEndDate);
            },

            getFile(id){
              api.get(this, api.PROPERTIES + this.property.id + '/files/' + id).then(r => {
                if(r.file && r.file.download_url){
                  window.open(r.file.download_url);
                  this.items.find(i => i.id === id).attributes.last_downloaded_on = r.file.attributes.last_downloaded_on;
                  this.items.find(i => i.id === id).attributes.downloaded_by = r.file.attributes.downloaded_by;
                }
              }).catch(err => {
                this.errorSet(this.$options.name, err);
              });
            },

            checkIfBottom(){
                window.onscroll = () => {
                    this.bottomVisible();
                }
            },

            async bottomVisible(dateRange, customStartDate, customEndDate){
                let bottomOfWindow = document.documentElement.scrollTop + document.documentElement.clientHeight >= document.documentElement.scrollHeight;
                if ((bottomOfWindow && !this.infinityLoader && !this.loadingDocuments && this.currentPage < this.totalPages) || this.items.length === 0) {
                    if(!dateRange || dateRange === 'All Time'){
                        await this.fetchData();
                    } else if(dateRange === 'Last 30 Days'){
                        await this.fetchData(dateRange);
                    } else if (dateRange === 'Custom' && customStartDate && customEndDate) {
                        await this.fetchData(dateRange, customStartDate, customEndDate);
                    }
                    if(this.items.length > 0){
                        await this.bottomVisible(dateRange, customStartDate, customEndDate);
                    }
                }
            }
        },
        watch: {
            customStartDate(){
              if(this.customStartDate === ''){
                this.customEndDate = '';
              }
            },
            async property(){
                await this.resetFilters();
            }
        }
    }



</script>

<style scoped>
    .select-filter {
      border: 1px solid #DFE3E8;
      border-radius:4px;
      width:175px;
      height: 40px;
      background:white;
    }

    .select-filter:hover {
      border: 1px solid #C4CDD5;
      cursor: pointer;
    }

    .select-filter.download-center-filters-active {
        border: 2px solid #00848E;
    }
</style>
